import logo from './logo.svg';
import './App.css';

import * as random from 'maath/random/dist/maath-random.esm'
import { OrbitControls, CameraShake, CameraControls, Points, PointMaterial } from '@react-three/drei'
import { useControls } from 'leva'
import { Particles } from './Particles'
import { Canvas, useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { gruvboxDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { BrowserRouter as Router, Route, useLocation, Routes } from 'react-router-dom';
import Page from './Page';

export default function App() {

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Page />} />
        <Route path="/docs" element={<Page />} />
        <Route path="*" element={<Page />} />
      </Routes>
    </Router>
  )
}
