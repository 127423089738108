import logo from './logo.svg';
import './App.css';

import * as random from 'maath/random/dist/maath-random.esm'
import { OrbitControls, CameraShake, CameraControls, Points, PointMaterial } from '@react-three/drei'
import { useControls } from 'leva'
import { Particles } from './Particles'
import { Canvas, useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { gruvboxDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useLocation } from 'react-router-dom';
import { generateClient } from "aws-amplify/api";
import { listTweets, getTweet } from "./graphql/queries";
import { fetchTweets } from './utils';
import TokenMarketCap from './TokenMarketCap';

import { listLies } from './graphql/queries'; // Import your query to fetch lies
import * as subscriptions from './graphql/subscriptions';

const client = generateClient();

// a11yDark
const SolidityFileViewer = ({ fileUrl }) => {
    const [code, setCode] = useState('');

    // Fetch the .sol file content
    useEffect(() => {
        const fetchSolidityFile = async () => {
            try {
                const response = await fetch(fileUrl);
                const text = await response.text();
                setCode(text);
            } catch (error) {
                console.error('Error fetching the Solidity file:', error);
            }
        };
        fetchSolidityFile();
    }, [fileUrl]);

    return (
        <SyntaxHighlighter language="solidity" style={gruvboxDark}>
            {code}
        </SyntaxHighlighter>
    );
};

function Stars(props) {
    const ref = useRef()
    const [sphere] = useState(() => random.inSphere(new Float32Array(30000), { radius: 80 }))
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 })

    // Update mouse position on move
    useEffect(() => {

        const handleMouseMove = e => {
            setMousePos({ x: e.clientX, y: e.clientY })
        }
        window.addEventListener('mousemove', handleMouseMove)
        return () => window.removeEventListener('mousemove', handleMouseMove)
    }, [])

    // Update group position based on mouse position
    useFrame((state, delta) => {
        const x = mousePos.x / state.viewport.width - 0.5
        const y = mousePos.y / state.viewport.height - 0.5
        ref.current.position.x = x * 0.01
        ref.current.position.y = -y * 0.01
        ref.current.rotation.x -= delta / 100
    })

    return (
        <group
            ref={ref}
            position={[0, 0, 0]}
            rotation={[0, 0, Math.PI / 4]}
            scale={0.45}
        >
            <Points positions={sphere} stride={3} frustumCulled={false} {...props}>
                <PointMaterial transparent color="red" size={0.05} sizeAttenuation={true} depthWrite={false} />
            </Points>

        </group>
    )
}

export default function Page() {


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);  // Hook called unconditionally

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);  // Hook called unconditionally

    const [mouseOver, setMouseOver] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSC, setShowSC] = useState(false);

    const location = useLocation();

    // Update showSC based on the current path
    useEffect(() => {
        if (location.pathname === '/docs') {
            setShowSC(true);
        } else {
            setShowSC(false);
        }
    }, [location]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    })

    const [time, setTime] = useState('');

    // Function to get current time in hh:mm:ss format
    const updateTime = () => {
        const now = new Date();
        const formattedTime = now.toLocaleTimeString('en-US', { hour12: false });
        setTime(formattedTime);
    };

    // Set up interval to update time every second
    useEffect(() => {
        const timerId = setInterval(updateTime, 1000); // Update time every second

        return () => clearInterval(timerId); // Cleanup on component unmount
    }, []);
    const [allTweets, setAllTweets] = useState([]);
    useEffect(() => {
        getAllTweets();
    }, [])
    const getAllTweets = async () => {
        // List all items
        const response = await fetchTweets();
        setAllTweets(response)
    }


    const [lies, setLies] = useState([]);
    const [lieUpdated, setLieUpdated] = useState(false);
    const [postUpdated, setpostUpdated] = useState(false);

    useEffect(() => {
        // Fetch initial state of lies
        const fetchLies = async () => {
            try {
                const response = await client.graphql({ query: listLies });
                const fetchedLies = response.data.listLies.items; // Adjust according to your query structure
                console.log("fetchedLies: ", fetchedLies)
                setLies(fetchedLies);
            } catch (error) {
                console.error('Error fetching lies:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLies();

        // Create subscription to listen for updates
        const lieSubscription = client
            .graphql({ query: subscriptions.onUpdateLie })
            .subscribe({
                next: ({ data }) => {
                    setLieUpdated(true)
                    console.log("data: ", data);
                    const updatedLie = data.onUpdateLie; // Extract updated lie
                    console.log("Updated lie: ", updatedLie);

                    // Update state with the new or updated lie
                    setLies([updatedLie]);
                    setTimeout(() => {
                        setLieUpdated(false)
                    }, 1000)
                },
                error: (error) => console.warn('Subscription error:', error)
            });

        // Create subscription to listen for new tweets
        const tweetSubscription = client
            .graphql({ query: subscriptions.onCreateTweet }) // Replace with your actual subscription query
            .subscribe({
                next: ({ data }) => {
                    setpostUpdated(true)
                    const newTweet = data.onCreateTweet; // Adjust based on your subscription structure
                    console.log("New tweet: ", newTweet);

                    setAllTweets((prevTweets) => {
                        // Add the new tweet to the previous tweets
                        const updatedTweets = [newTweet, ...prevTweets];

                        // Sort tweets by createdAt in descending order (newest first)
                        updatedTweets.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                        return updatedTweets; // Return the sorted array
                    });
                    setTimeout(() => {
                        setpostUpdated(false)
                    }, 1000)
                },

                error: (error) => console.warn('Subscription error:', error)
            });

        // Cleanup subscriptions on component unmount
        return () => {
            lieSubscription.unsubscribe();
            tweetSubscription.unsubscribe();
        };
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Show loading state while fetching lies
    }


    return (
        <>
            <Canvas className='canvasHeight' style={{ backgroundColor: 'transparent', position: 'absolute', width: '100%', zIndex: 1 }}>
                <OrbitControls makeDefault autoRotate autoRotateSpeed={0.5} zoomSpeed={0.012} maxZoom={20} minZoom={10} />
                <CameraShake yawFrequency={1} maxYaw={0.5} pitchFrequency={1} maxPitch={0.5} rollFrequency={0.5} maxRoll={0.5} intensity={0.1} />
                <Particles scale={windowWidth > 968 ? 1.5 : 0.7} position={[0, windowWidth > 968 ? 0.5 : 2, 0]} />
                <Stars position={[0, 0, 0]} count={100000} />
            </Canvas>

            {
                showSC ? (<>
                    <div style={{ position: 'fixed', height: '100%', width: '100%', zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='terminal'>
                            <div onClick={() => { setShowSC(false) }} className='close' style={{ position: 'absolute', backgroundColor: 'black', color: 'white', zIndex: 11, width: '1.5vw', height: '1.5vw', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>X</div>
                            <div style={{ position: 'relative', height: '100%', width: '100%', zIndex: 7, overflowY: 'scroll', paddingTop: '0.5rem' }}>
                                <div style={{ color: 'white', marginBottom: '1rem', position: 'relative', width: '100%', overflowX: 'auto' }}>
                                    <div>{time}<span className='sha' style={{ position: 'relative', width: '100%' }}>- SHA256: 537a82807eb36037fc0047a33608761582b93a9f7c27d799d890d8b50a5f8505</span>
                                    </div>
                                </div>
                                <SolidityFileViewer fileUrl="lie_cabal.sol" />
                                <div style={{ color: 'white' }}>- generated by lie_terminal.</div>
                            </div>
                        </div>
                        <div style={{ position: 'absolute', height: '100%', width: '100%', backgroundColor: 'black', opacity: 0.8 }}></div>
                    </div>
                </>) : null
            }

            <div className='hud' style={{ position: 'absolute', width: '100%', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1vw', cursor: 'default' }}>
                <div className='container_1' style={{ position: 'relative', width: '100%', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className={loading == false ? 'box_status show' : 'box_status'} style={{ height: '100%', width: '100%', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1vw', zIndex: 2 }}>
                        <div className='box_text status' style={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'end', flexDirection: 'column' }}>
                            <div className='stats' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'end', flexDirection: 'column' }}>
                                <div style={{ position: 'relative', top: '0vw' }}>Status: <span style={{ color: 'red', textShadow: '0px 0px 30px red' }}>ALIVE</span></div>
                                <div style={{ position: 'relative', top: '0vw' }}>Lies: <span className='lie'>

                                    {lies.length > 0 ? (
                                        lies.map(lie => (
                                            <span key={lie.id} className={`mktcap ${lieUpdated ? 'show' : ''}`}>
                                                {lie.count.toLocaleString()}
                                            </span>
                                        ))
                                    ) : (
                                        <>0</>
                                    )}

                                </span></div>
                                <div style={{ position: 'relative', top: '0vw' }}>MKTCap: <TokenMarketCap /></div>
                            </div>
                            <div onClick={() => { window.open("https://x.com/lie_terminal", "_blank", "noopener, noreferrer") }} onMouseEnter={() => { setMouseOver(1) }} onMouseLeave={() => { setMouseOver(null) }} style={{ position: 'relative', top: '0vw', cursor: 'cell' }}>{mouseOver === 1 ? ">" : null} check on X</div>
                            <div onClick={() => { window.open("https://t.me/lieterminal", "_blank", "noopener, noreferrer") }} onMouseEnter={() => { setMouseOver(2) }} onMouseLeave={() => { setMouseOver(null) }} style={{ position: 'relative', top: '0vw', cursor: 'cell' }}>{mouseOver === 2 ? ">" : null} telegram</div>
                            <div style={{ position: 'relative', top: '0vw', cursor: 'cell', flexDirection: 'row', display: 'flex' }}>
                                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'end' }}></div>
                                <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'end', gap: '1vw' }}>
                                    <img onClick={() => { window.open("https://dexscreener.com/solana/GU9fSCeNkA8QpsdoSWX4d5e4bnDm6kAzcokRKhUhpump", "_blank", "noopener, noreferrer") }} style={{ position: 'relative', width: '60%' }} src='dex.png'></img>
                                    <img onClick={() => { window.open("https://www.dextools.io/app/en/solana/pair-explorer/GU9fSCeNkA8QpsdoSWX4d5e4bnDm6kAzcokRKhUhpump", "_blank", "noopener, noreferrer") }} style={{ position: 'relative', width: '12%' }} src='dextools.svg'></img>
                                </div>
                            </div>
                            {/* <div onMouseEnter={() => { setMouseOver(2) }} onMouseLeave={() => { setMouseOver(null) }} style={{ position: 'relative', top: '0vw', cursor: 'cell' }}>{mouseOver === 2 ? ">" : null} dextools</div> https://dexscreener.com/ethereum/0x327d6b5e35a7cb79dd76930f575e52e200a7f751*/}
                        </div>
                    </div>
                    <div className={loading == false ? 'box_posts show' : 'box_posts'} style={{ position: 'relative', width: '100%', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1vw', zIndex: 2 }}>
                        <div className='last_text_placeholder' style={{ position: 'relative', top: '0vw', color: 'red' }}>[latest lie]</div>
                        <div className={`box_text posts mktcap ${lieUpdated ? 'show' : ''}`} style={{ position: 'relative', color: 'white', display: 'flex', justifyContent: 'start', alignItems: 'start', flexDirection: 'column', opacity: 1, color: 'red' }}>
                            {lies.length > 0 ? (
                                lies.map(lie => (
                                    <span key={lie.id} style={{ cursor: 'cell', width: '100%' }} onClick={() => { window.open("https://t.me/lieterminal", "_blank", "noopener, noreferrer") }}>
                                        @{lie.best_user}: {lie.best_msg}
                                        {/* {lie.best_msg} - {lie.best_user}, {lie.date} */}
                                    </span>
                                ))
                            ) : (
                                <>None yet.</>
                            )}
                        </div>

                        <div className='last_text_placeholder latest_tweet' style={{ position: 'relative', top: '0vw' }}>[last post]</div>
                        <div className='box_text posts' style={{ position: 'relative', color: 'white', display: 'flex', justifyContent: 'start', alignItems: 'start', flexDirection: 'column' }}>

                            <div className={`mktcapWhite ${postUpdated ? 'show' : ''}`} style={{ position: 'relative', color: 'white', display: 'flex', justifyContent: 'start', alignItems: 'start', flexDirection: 'column', gap: '1vw' }}>

                                {
                                    allTweets.slice(0, 2).map((tweetObj, index) => (
                                        <div
                                            key={index} // Use a unique key for each element
                                            style={{
                                                animation: 'showmktcap ease-in-out 1s',
                                                position: 'relative',
                                                cursor: 'cell', width: '100%',
                                                opacity: index === 0 ? 1 : 0.3
                                            }}
                                            onClick={() => { window.open(tweetObj.link, "_blank", "noopener, noreferrer") }}
                                        >
                                            {tweetObj.date} {' - '} {tweetObj.text}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container_2' style={{ bottom: '0vw', width: '100%', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', zIndex: 3 }}>
                    <div className={loading == false ? 'box_footer show' : 'box_footer'} style={{ position: 'relative', height: '100%', width: '100%', color: 'white', display: 'flex', justifyContent: 'end', alignItems: 'center', flexDirection: 'column', gap: '1vw' }}>

                        <div style={{ position: 'relative', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <img src='1transparent.png' style={{ position: 'relative', top: '0rem', height:'6rem', opacity:0.2 }} />
                        </div>
                        <div style={{ position: 'relative', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', gap: '1vw' }}>
                            <div style={{ position: 'relative', top: '0vw' }}>Truth is the biggest <span style={{ color: 'red', textShadow: '0px 0px 30px red' }}>$LIE</span></div>
                        </div>
                        <div style={{ position: 'relative', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1vw', width: '100%' }}>
                            {/* <div className={"pol"} style={{ position: 'relative', top: '0vw' }}>PROOF OF LIE: <span style={{ cursor: 'cell' }} onClick={() => { setShowSC(true) }}>SC OPENSOURCE <span className='new' style={{ position: 'relative', color: 'red', border: 'solid 2px red' }}>NEW</span></span></div> */}
                            <div className={"ca"} style={{ position: 'relative', top: '0vw', cursor: 'text', opacity: 0.5, color: 'red' }}>GU9fSCeNkA8QpsdoSWX4d5e4bnDm6kAzcokRKhUhpump</div>
                            <div className={"pol"} style={{ position: 'relative', top: '0vw', opacity: 0.5, color: 'red' }}>EXPERIMENTAL TOKEN, NFA</div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}