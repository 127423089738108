/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLie = /* GraphQL */ `
  subscription OnCreateLie($filter: ModelSubscriptionLieFilterInput) {
    onCreateLie(filter: $filter) {
      id
      count
      best_msg
      best_user
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLie = /* GraphQL */ `
  subscription OnUpdateLie($filter: ModelSubscriptionLieFilterInput) {
    onUpdateLie(filter: $filter) {
      id
      count
      best_msg
      best_user
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLie = /* GraphQL */ `
  subscription OnDeleteLie($filter: ModelSubscriptionLieFilterInput) {
    onDeleteLie(filter: $filter) {
      id
      count
      best_msg
      best_user
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTweet = /* GraphQL */ `
  subscription OnCreateTweet($filter: ModelSubscriptionTweetFilterInput) {
    onCreateTweet(filter: $filter) {
      id
      text
      date
      image
      link
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTweet = /* GraphQL */ `
  subscription OnUpdateTweet($filter: ModelSubscriptionTweetFilterInput) {
    onUpdateTweet(filter: $filter) {
      id
      text
      date
      image
      link
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTweet = /* GraphQL */ `
  subscription OnDeleteTweet($filter: ModelSubscriptionTweetFilterInput) {
    onDeleteTweet(filter: $filter) {
      id
      text
      date
      image
      link
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTodo = /* GraphQL */ `
  subscription OnCreateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onCreateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTodo = /* GraphQL */ `
  subscription OnUpdateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onUpdateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTodo = /* GraphQL */ `
  subscription OnDeleteTodo($filter: ModelSubscriptionTodoFilterInput) {
    onDeleteTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
