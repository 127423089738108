

import { generateClient } from "aws-amplify/api";
import { listTweets, getTweet, listLies } from "./graphql/queries";
const client = generateClient()

export const fetchTweets = async () => {
    let allTweets = [];
    let nextToken = null; // Initialize nextToken to null

    do {
        // Fetch tweets with pagination
        const response = await client.graphql({
            query: listTweets,
            variables: { nextToken } // Pass nextToken as a variable if supported
        });

        // Extract tweets and nextToken from the response
        const tweets = response['data']['listTweets']['items'];
        nextToken = response['data']['listTweets']['nextToken']; // Get the nextToken for the next page

        // Append the fetched tweets to the allTweets array
        allTweets = [...allTweets, ...tweets];

    } while (nextToken);

    // Sort tweets by createdAt in descending order (newest first)
    allTweets.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    console.log("Fetched allTweets: ", allTweets);

    return allTweets;
};

export const fetchLies = async () => {
    let lies;
    let nextToken = null; // Initialize nextToken to null

    do {
        // Fetch tweets with pagination
        const response = await client.graphql({
            query: listLies,
            variables: { nextToken } // Pass nextToken as a variable if supported
        });

        // Extract tweets and nextToken from the response
        const lie = response['data']['listLies']['items'][0];
        nextToken = response['data']['listLies']['nextToken']; // Get the nextToken for the next page

        // Append the fetched tweets to the allTweets array
        lies = [...lies, ...lie];

        console.log("Fetched lies: ", lies);
    } while (nextToken);


    return lies;
};
