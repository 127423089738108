import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TokenMarketCap = () => {
  const [marketCap, setMarketCap] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showClass, setShowClass] = useState(false); // State to manage the "show" class
  const tokenAddress = 'GU9fSCeNkA8QpsdoSWX4d5e4bnDm6kAzcokRKhUhpump'; // Your token address

  useEffect(() => {
    const fetchMarketCap = async () => {
      try {
        // Fetch token data from DexScreener
        const response = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`);
        const data = response.data;

        if (data && data.pairs && data.pairs.length > 0) {
          const tokenData = data.pairs[0]; // Get the first pair for the token

          // The market cap is typically provided in the response directly
          const calculatedMarketCap = tokenData.marketCap; // Adjust based on actual data structure

          setMarketCap(calculatedMarketCap);
          
          // Show the class when market cap is updated
          setShowClass(true);
          setTimeout(() => {
            setShowClass(false); // Remove the class after 1 second
          }, 1000);
        } else {
          setMarketCap(null);
        }
      } catch (error) {
        console.error('Error fetching market cap:', error);
        setMarketCap(null);
      } finally {
        setLoading(false);
      }
    };

    // Initial fetch
    fetchMarketCap();

    // Set up interval to refresh market cap every 10 seconds
    const intervalId = setInterval(fetchMarketCap, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [tokenAddress]);

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      {marketCap !== null ? (
        <span className={`mktcap ${showClass ? 'show' : ''}`}>${marketCap.toLocaleString()}</span>
      ) : (
        <>Incoming...</>
      )}
    </>
  );
};

export default TokenMarketCap;
