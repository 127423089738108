/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLie = /* GraphQL */ `
  query GetLie($id: ID!) {
    getLie(id: $id) {
      id
      count
      best_msg
      best_user
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLies = /* GraphQL */ `
  query ListLies(
    $filter: ModelLieFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        count
        best_msg
        best_user
        date
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTweet = /* GraphQL */ `
  query GetTweet($id: ID!) {
    getTweet(id: $id) {
      id
      text
      date
      image
      link
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTweets = /* GraphQL */ `
  query ListTweets(
    $filter: ModelTweetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTweets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        date
        image
        link
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
